import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ApiConfigService } from './apiConfigService';

@Injectable({
  providedIn: 'root'
})
export class CashMgmtService {

  api = this.apiConfig.getBaseUrl;
  intialCash = new BehaviorSubject<any>(null);

  constructor(private apiConfig: ApiConfigService) {
  }
  

  /**
   * Get loyalty points
   * @returns 
   */
  getloyaltyPoints(): Observable<any> {
    return this.apiConfig.get(`${this.api}configuration/getByParameter/pointsPerDoller`);
  }



  /**
   * Get leaderboard data
   * @returns 
   */
  getLeaderboardData(size:number): Observable<any> {
    return this.apiConfig.get(`${this.api}user/customer/leaderboard/${size}`);
  }

  



}
