import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfigService } from './apiConfigService';


@Injectable({
  providedIn: 'root'
})
export class MessageService {

  api = this.apiConfig.getBaseUrl;

  constructor(
    private apiConfig: ApiConfigService) { }


  subscribeNotification(type, mobileNumber) {
    return this.apiConfig.put( `${this.api}marketing/subscribe/${type}/${mobileNumber}`, null);
  }

  unsubscribeNotification(type, mobileNumber) {
    return this.apiConfig.put( `${this.api}marketing/unsubscribe/${type}/${mobileNumber}`, null);
  }
  getNotificationStatus(mobileNumber) : Observable<any> {
    return this.apiConfig.get(`${this.api}marketing/unsubscribeTypes/${mobileNumber}`);
  }
}
