import { BehaviorSubject } from 'rxjs';
import { IProduct } from '../appModels/IProduct';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ProductStorage {
    productList = new BehaviorSubject<IProduct[]>(null);
    constructor() { }

    /**
     * Add product
     * @param product 
     */
    addProduct(product: IProduct): void {
        let currentState = this.productList.value;
        if (!currentState) {
            currentState = [];
        }
        const updatedState = [...currentState, product];
        this.productList.next(updatedState);
    }

    /**
     * get product by id
     * @param id 
     */
    getProductById(id: number): IProduct {
        if (this.productList.value) {
            return this.productList.value.find(e => e.id == id);
        }
        return null;
    }
}