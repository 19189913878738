import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfigService } from './apiConfigService';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  api = this.apiConfig.getBaseUrl;

  constructor(
    private apiConfig: ApiConfigService) { }

  notifyRestaurant(notifyData): Observable<any> {
    return this.apiConfig.post(this.api + 'sms/notify-restaurant', notifyData);
  }

}
