import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfigService } from './apiConfigService';

@Injectable({
  providedIn: 'root'
})
export class RasturantService {

  api = this.apiConfig.getBaseUrl + 'product';

  constructor(
    private apiConfig: ApiConfigService) { }

  getProductsByCategoryId(id): Observable<any>{
    return this.apiConfig.get(this.api + '/category/' + id + "?client=customer");
  }
  
  getProductByProductId(productId: number): Observable<any>{
    return this.apiConfig.get(this.api + '/' + productId);
  }
}
